<template>
	<div>
		<!-- 圣杯布局 -->
		<Header style="position: fixed;"></Header>
		<div style="height: 60px;"></div>
		<div class="banner" ></div>
		
		<div class="container">
			<div class="leftRight"></div>
			<!-- 内容 -->
			<section class="center" style="color: #4B4B4B;">
				<!-- 公司简介 -->
				<div class="vue-flex">
					<div style="margin-right: 80px;">
						<el-image :src="require('../static/images/index/Mask-group.png')" style="width: 500px;height: 400px;"></el-image>
					</div>
					<div>
						<div class="text_module_title">公司简介</div>
						<div class="text_module_subtitle">聚焦于数字信息、数字安全、数字文化、数字能源四大产业</div>
						<div class="text_style">
							<p style="line-height: 25px;">广东网宇科技股份有限公司（以下简称“网宇科创”）是一家专注数字化产业链接与科技创新孵化的平台公司，聚焦于数字信息、数字安全、数字文化、数字能源四大产业。成立近十年以来，致力于产业数字化的创新与深度融合，助力不同行业数字科技创新与产业升级，实现价值共赢。</p>
							<ul style="padding-left: 15px;line-height: 25px;margin-top: 20px;">
								<li>数字信息，包括数字应用系统与平台、网络信息化软硬件等领域。</li>
								<li>数字安全，包括以运维安全、数据治理、工业数字化等安全领域。</li>
								<li>数字文化，包括数字影视、数字传媒、数字设计、数字教育、数字图文等领域。</li>
								<li>数字能源，包括数字化绿色能源、数字化储能等相应领域。</li>
							</ul>
						</div>
					</div>
				</div>
				<!-- 团队优势 -->
				<div>
					<div class="module_title">团队优势</div>
					<div class="module_subtitle">释放潜能，引领创新</div>
					<div>
						<div style="position: relative;">
							<el-image :src="require('../static/images/about/image 13.jpg')"></el-image>
							<div style="position: absolute;top: 80px;right: 70px;color: #fff;width: 400px;">
								<p style="font-size: 24px;font-weight: 600;margin-bottom: 10px;">
									创始人
								</p>
								<p style="line-height: 24px">
									网宇科创的创始人兼CEO王宇杰先生，是中国互联网界的连续创业者和天使投资人，以其独到的商业洞察力和创新精神成功创办和孵化了众多行业领先的企业，包括但不限于上市公司南兴装备的全资子公司唯一网络、新三板挂牌企业帝恩思科技、堡塔安全、飞影云、青松智慧、华易能源以及图居科技等，具备较为丰富的创业和投资经验。
								</p>
							</div>
						</div>
						<div class="flex-between" style="margin-top: 50px;">
							<div style="position: relative;">
								<el-image :src="require('../static/images/about/image 14.png')" style="width: 570px;height: 300px;"></el-image>
								<div style="position: absolute;top: 150px;right: 30px;color: #fff;width: 500px;">
									<p style="font-size: 24px;font-weight: 600;margin-bottom: 10px;">
										专业团队
									</p>
									<p style="line-height: 24px">
										公司有一支专业的投资及运营管理团队，来自于国内外知名高校、互联网企业及电信基础运营商，团队成员具备丰富的投资及运营管理经验，在网络安全、运维安全、数字影视、数字传媒及新能源领域有着较深入的理解和行业沉淀。
									</p>
								</div>
							</div>
							<div style="position: relative;">
								<el-image :src="require('../static/images/about/image 15.png')" style="width: 570px;height: 300px;"></el-image>
								<div style="position: absolute;top: 150px;right: 30px;color: #fff;width: 500px;">
									<p style="font-size: 24px;font-weight: 600;margin-bottom: 10px;">
										专家智库
									</p>
									<p style="line-height: 24px">
										网宇科创与多所大学和科研机构共同研发合作，与行业内知名企业、众多业内创业导师及行业专家进行交流合作，以专家智库资源帮助被投企业实现技术突破和产品创新，支持初创企业快速定位市场并发展壮大。
									</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			<div class="leftRight"></div>
		</div>
		<Footer style="margin-top: 100px;"></Footer>
	</div>
</template>

<script>
	import Header from "@/components/header";
	import Footer from "@/components/footer";
	export default {
		data() {
			return {
				
			}
		},
		components: {
			Header,
			Footer,
		},
		mounted() {
		    
		},
		methods: {},
	}
</script>

<style scoped>
	.container {
		margin-top: 70px;
		display: flex;
		flex-direction: row;
		flex: 1;
	}
	.center {
		flex: 0 0 1200px;
	}
	.leftRight {
		flex: 1;
	}
	.footer {
		text-align: center;
	}
	.banner {
		height: 55vh;
		display: flex;
		align-items: center;
		justify-content: center;
		background: url("../static/images/about/222.jpg") no-repeat center center;
		background-size: cover; 
	}
	.text_module_title {
		font-size: 28px;
		font-weight: 400;
		color: #4B4B4B;
	}
	.text_module_subtitle {
		margin: 20px 0 30px 0;
		font-size: 16px;
		color: #D6B98E;
	}
	.text_style {
		font-size: 16px;
	}
	.text_style p {
		/* text-indent: 2em; */
		margin-top: 10px;
	}
	
	.module_title {
		font-size: 28px;
		font-weight: 400;
		color: #4B4B4B;
		text-align: center;
		margin: 100px 0 20px 0;
	}
	.module_subtitle {
		margin-bottom: 60px;
		font-size: 16px;
		color: #D6B98E;
		text-align: center;
	}
</style>
